<template>
  <b-container>
    <transition name="slide">
      <b-row v-if="isVisible && homeScreen">
        <b-col class="p-3 align-center">
          <b-img width="240" class="mt-5 mb-5" src="./img/logo-preta.png"></b-img>
          <div class="title-text"> {{ animatedText0 }} </div>
          <div class="title-description mt-5 bold">
            {{ animatedText0Sub }} 
            
          </div>
        </b-col>
      </b-row>
    </transition>



    <transition name="slide">
      <b-row v-if="isVisible && !homeScreen">
        <b-col class="p-3">
          <div class="title-text">Olá, bom ter você conosco!</div>
          <div class="title-description">
            Precisamos de alguns dados para iniciar a simulação do seu seguro.
          </div>
          <div class="title-description">
            Fique tranquilo(a) seus dados estão seguros conosco.
          </div>
        </b-col>
      </b-row>
    </transition>

    <ValidationObserver v-slot="{ invalid }" ref="observer">
      <transition name="slide">
        <form v-if="isVisible && !homeScreen">
          <b-row class="mb-3">
            <b-col md="6">
              <ValidationProvider rules="required|nome_completo" v-slot="{ errors }" vid="nome" name="Nome">
                <CustomTextInput v-model="fields.name" :model="fields.name" :placeholder="'Nome Completo'"
                  :errors="errors" type="text" />
              </ValidationProvider>
            </b-col>
            <b-col md="2">
              <ValidationProvider v-slot="{ errors }" name="email" rules="required|email">
                <CustomTextInput v-model="fields.email" :model="fields.email" :errors="errors" :placeholder="'Email'"
                  type="email" />
              </ValidationProvider>
            </b-col>
            <b-col md="2">
              <ValidationProvider v-slot="{ errors }" name="Data de Nascimento"
                rules="required|data_nascimento|data_maior_idade">
                <CustomTextInput v-model="fields.birthday" :model="fields.birthday" :errors="errors"
                  v-mask="'##/##/####'" :placeholder="'Data de nascimento'" type="tel" />
              </ValidationProvider>
            </b-col>
            <b-col md="2">
              <ValidationProvider v-slot="{ errors }" name="telefone" rules="required|validacao_telefone">
                <CustomTextInput :errors="errors" v-model="fields.cellphone" :model="fields.cellphone"
                  :placeholder="'Telefone'" v-mask="['(##) #####-####']" type="tel" />
              </ValidationProvider>
            </b-col>
            <b-col md="2">
              <div @click="openzada">
                <CustomTextInput :isDisabled="true" :placeholder="placeholderUF" type="text" :isDrawer="true" />
                <span v-if="showErrors.uf" class="error-msg">O campo UF é obrigatório</span>
              </div>
            </b-col>
          </b-row>
        </form>
      </transition>
      <transition name="slide">
        <button v-if="isVisible && !homeScreen" id="footerButton" class="footer-button" @click.prevent="handleClick"
          :disabled="invalid || loading">
          Avançar
        </button>
      </transition>
    </ValidationObserver>
    <Drawer drag-color="#f6f2fe" :max-height="400" ref="myBottomSheet">
      <template #header>
        <div class="text-drawer">UF</div>

        <div class="box-padding">
          <div class="input-box">
            <svg width="20" heigth="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15C11.381 15 12.6296 14.4415 13.5355 13.5355C14.4415 12.6296 15 11.381 15 10C15 7.23858 12.7614 5 10 5ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 11.5719 16.481 13.0239 15.6063 14.1921L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L14.1921 15.6063C13.0239 16.481 11.5719 17 10 17C6.13401 17 3 13.866 3 10Z"
                  fill="#9c9b9c"></path>
              </g>
            </svg>

            <input type="text" class="input-search" v-model="searchQuery" placeholder="Consultar"
              @input="handleSearch" />
            <svg @click="clearQuery" width="20" heigth="20" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path opacity="0.5"
                  d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                  fill="#9e9e9e"></path>
                <path
                  d="M8.96967 8.96967C9.26256 8.67678 9.73744 8.67678 10.0303 8.96967L12 10.9394L13.9697 8.96969C14.2626 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232 9.26258 15.3232 9.73746 15.0303 10.0304L13.0607 12L15.0303 13.9696C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0304 15.0303C9.73746 15.3232 9.26258 15.3232 8.96969 15.0303C8.6768 14.7374 8.6768 14.2626 8.96969 13.9697L10.9394 12L8.96967 10.0303C8.67678 9.73744 8.67678 9.26256 8.96967 8.96967Z"
                  fill="#9e9e9e"></path>
              </g>
            </svg>
          </div>
        </div>
      </template>
      <div class="drawer">
        <ul class="list-group">
          <li v-for="option in filteredOptions" :key="option.id" @click="selectUF(option)" class="list-drawer">
            {{ option }}
          </li>
        </ul>
      </div>
    </Drawer>
    <transition v-if="isVisible && !homeScreen" name="slide">
      <b-row>
        <b-col class="p-3" style="margin-bottom: 60px;">
          <div class="title-description">Ao se cadastrar você concorda com nossos</div>
          <div class="title-link" @click="useterms">termos de uso.</div>
        </b-col>
      </b-row>
    </transition>


  </b-container>
</template>

<script>

import CustomTextInput from "@/components/inputs/custom-text-input/CustomTextInput.vue";
import VueBottomSheetVue2 from "@webzlodimir/vue-bottom-sheet-vue2";
import * as D from "@/duck";

export default {
  props: {
    clickHandler: {
      type: Function,
      required: true,
    },
    previousStep: {
      type: Function,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CustomTextInput: CustomTextInput,
    Drawer: VueBottomSheetVue2,
  },
  data() {
    return {
      homeScreen: true,
      cities: D.constants.UFS,
      open: false,
      receivedConstant: null,
      searchQuery: "",
      selectedUF: "",
      filteredOptions: [],
      isVisible: false,
      nameValue: "",
      placeholderUF: "UF",
      fields: {
        name: "",
        email: "",
        birthday: "",
        cellphone: "",
        uf: "",
      },
      showErrors: {
        name: false,
        email: false,
        birthday: false,
        cellphone: false,
        uf: false,
      },
      texto0: "",
      texto0Sub: "",
      animatedText0: "",
      animatedText0Sub: ""
    };
  },
  methods: {

    useterms() {
      window.open('https://registro.bikeregistrada.com.br/politicadeprivacidade.html', "_blank");
    },
    async handleClick() {
      if (!this.fields.uf) {
        this.showErrors.uf = true;
      }
      if (this.fields.uf) {
        this.emitPersonalData();
        await this.clickHandler();
      }
    },
    async initializeFields() {
      if (this.data) {
        this.fields.name = this.data.name;
        this.fields.email = this.data.email;
        this.fields.birthday = this.data.birthday;
        this.fields.uf = this.data.uf;
        this.fields.cellphone = this.data.cellphone;
        this.placeholderUF = this.data.uf || "UF";
      }
      
      setTimeout(() => {
        this.typeText();
      }, 300);
      setTimeout(() => {
        this.homeScreen = false;
      }, 5000);
    },

    async typeText() {
      this.texto0 = "Olá, bem vindo(a) a cotação da proteção";
      this.texto0Sub = "Vamos juntos encontrar a melhor oferta para sua proteção.";
      await this.textSpeak();

    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async textSpeak() {
      this.animatedText0 = "";
      this.animatedText0Sub = "";
      for (let index = 0; index < this.texto0?.length; index++) {
        this.animatedText0 += this.texto0.charAt(index);
        await this.delay(20);
      }

      for (let index = 0; index < this.texto0Sub?.length; index++) {
        this.animatedText0Sub += this.texto0Sub.charAt(index);
        await this.delay(15);
      }
    },

    clearQuery() {
      this.searchQuery = "";
      this.handleSearch();
    },
    handleSearch() {
      const query = this.searchQuery.toLowerCase();
      this.filteredOptions = this.cities.filter((option) =>
        option.toLowerCase().includes(query)
      );
    },
    selectUF(option) {
      this.showErrors.uf = false;
      this.fields.uf = option;
      this.placeholderUF = option;
      this.close();
    },
    openzada() {
      this.$refs.myBottomSheet.open();
    },
    close() {
      this.$refs.myBottomSheet.close();
    },
    validateField(field) {
      this.$refs.observer.validate(field).then(() => {
        if (field === "uf" && this.showErrors.uf === false) {
          this.showErrors[field] = false;
        } else {
          this.showErrors[field] = true;
        }
      });
    },
    emitPersonalData() {
      this.$emit("personalData", this.fields);
    },
  },
  created() {
    this.filteredOptions = [...this.cities];
  },
  mounted() {
    this.initializeFields();

    this.isVisible = true;
    const content = this.$el.querySelector(".bottom-sheet__content");
    if (content) {
      content.style.backgroundColor = "#f6f2fe";
    }
  },

};
</script>
<style scoped>
.col,
.col-2,
.col-6 {
  padding: 0 10px;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to

/* Início e fim da animação */
  {
  transform: translateX(-100%);
}

.title-text {
  font-size: 23px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
}

.input-search {
  padding: 5px;
  color: #908e95;
  border: none;
  background-color: #e0e0df;
  width: 100%;
  justify-self: center;
  outline: none;
}

.input-search ::placeholder {
  color: #908e95;
}

.continue-button {
  background-color: #5db247;
  border-radius: 25px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  padding: 12px 30px;
  max-width: 120px;
  position: absolute;
  bottom: 1%;
  right: 2%;
}

.bottom-sheet__content {
  background: #c21010;
}

.bottom-sheet__content {
  background: #c21010 !important;
  /* Força a aplicação da cor de fundo */
}

.list-drawer {
  text-align: left;
  padding: 8px;
  margin-left: 10px;
  margin-top: 15px;
  list-style-type: none;
}

.text-drawer {
  color: #4fa935;
  padding: 8px;
  margin-left: 10px;
  font-size: 24px;
  font-weight: 700;
}

.input-box {
  width: 100%;
  background-color: #e0e0df;
  border-radius: 12px;
  display: flex;
  padding: 8px;
}

.footer-button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 60px;
  background-color: #4fa935;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  z-index: 9999;
}

.footer-button:disabled {
  background-color: #b1cead;
  cursor: default;
}

.footer-button-back {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 30%;
  height: 60px;
  z-index: 999;
  background-color: #414040;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.drawer {
  margin-top: 5px;
  display: block;
  padding: 8px;
  width: 100%;
}

.box-padding {
  padding: 8px;
}

.modal-body {
  position: inherit;
}

.error-msg {
  font-size: 14px;
  line-height: 20px;
  color: #f70000;
}

.title-link {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
  text-decoration: underline;
}

.title-description {
  font-size: 16px;
  margin-top: 10px;
  line-height: 24px;
  color: #919ab0;
}
</style>
